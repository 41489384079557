import Swiper from 'swiper';

class ImagePinsBlock {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.pin = this.root.querySelectorAll('[data-image-pins-block-pin]');
        this.button = this.root.querySelectorAll(
            '[data-image-pins-block-button]'
        );
        this.content = this.root.querySelectorAll(
            '[data-image-pins-block-content]'
        );

        this.slider = this.root.querySelector('[data-image-pins-block-slider]');
        this.sliderInstance = null;

        return true;
    }

    init() {
        this.initButtons();
        this.setContentPos();
        this.initPins();
        this.initSlider();

        window.addEventListener('resize', this.setContentPos.bind(this));
        window.addEventListener('resize', this.onResize.bind(this));
        this.onResize();
    }

    initSlider() {
        const { innerWidth } = window;
        if (!this.slider || innerWidth > 992) return;

        this.sliderInstance = new Swiper(this.slider, {
            slidesPerView: 1.2,
            spaceBetween: 16,
        });
    }

    initPins() {
        if (!this.pin) return;

        this.pin.forEach(pin => {
            window.addEventListener('click', e => {
                if (!pin.contains(e.target)) pin.classList.remove('active');
            });
        });
    }

    initButtons() {
        if (!this.button) return;

        this.onButton = this.onButtonClick.bind(this);
        this.button.forEach(item => {
            item.addEventListener('click', this.onButton);
        });
    }

    onButtonClick(event) {
        const { currentTarget } = event;
        const parentPin = currentTarget.closest('.image-pins-block__pin');

        this.pin.forEach(item => {
            item.classList.remove('active');
        });

        if (parentPin) parentPin.classList.add('active');
    }

    setContentPos() {
        const { innerWidth } = window;
        if (!this.content || innerWidth < 992) return;

        this.content.forEach(item => {
            const contentRect = item.getBoundingClientRect();
            const { right, left } = contentRect;

            if (left < 0) {
                const transformX = 0 - left;
                item.style.transform = `translate(${transformX}px, 0)`;
            }

            if (right > innerWidth) {
                const transformX = innerWidth - right - 32;
                item.style.transform = `translate(${transformX}px, 0)`;
            }
        });
    }

    onResize() {
        const { innerWidth } = window;

        if (innerWidth > 992) {
            if (this.sliderInstance) {
                this.sliderInstance.destroy();
                this.sliderInstance = null;
            }
        } else {
            if (!this.sliderInstance) this.initSlider();
        }
    }
}

const sections = document.querySelectorAll('[data-image-pins-block]');
const initImagePins = sections => {
    if (sections) {
        sections.forEach(section => new ImagePinsBlock(section));
    }
};

initImagePins(sections);
